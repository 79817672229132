<style lang="scss" scoped>
a.ist {
  color: rgb(30, 60, 117) !important;
  font-weight: bold;
}
p {
  font-size: 18px;
}
h1 {
  color: rgb(24, 174, 194);
  margin: 15px 0;
}
ul {
  font-size: 18px !important;
}
ul li {
  list-style: none;
}
ul li::before {
  content: '-';
  padding-right: 10px;
}
</style>
<template>
  <b-container>
    <b-row>
      <b-col cols="12">
        <b-breadcrumb :items="items"></b-breadcrumb>
      </b-col>
      <b-col cols="12">
        <h1>Подача документов для поступления</h1>
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col cols="12">
        <p>Приемная комиссия начинает свою работу с <b>20.06.2025 г.!</b></p>
        <p>
          При подаче заявления в приемную комиссию в очном режиме, необходимо предоставить следующие документы
          (оригиналы и копии):
        </p>
        <ul>
          <li>
            <a
              class="ist"
              download="Заявление абитуриента 2024-2025 на базе 9 классов"
              target="_blank"
              href="/file/Заявление абитуриента 2024-2025 на базе 9 классов 2025.docx"
              >Заполненное заявление</a
            >
            на базе 9 классов;
          </li>
          <li>
            <a
              class="ist"
              download="Заявление абитуриента 2024-2025 на базе 11 классов"
              target="_blank"
              href="/file/Заявление абитуриента 2024-2025 на базе 11 классов 2025.docx"
              >Заполненное заявление</a
            >
            на базе 11 классов;
          </li>
          <li>
            <a
              class="ist"
              download="Заполненное согласие на обработку персональных данных от абитуриента (заполняется абитуриентом).doc"
              href="/file/Заполненное согласие на обработку персональных данных от абитуриента (заполняется абитуриентом).doc"
            >
              Заполненное согласие на обработку персональных данных от абитуриента
            </a>
          </li>
          <li>
            <a
              class="ist"
              download="Заполненное согласие на обработку персональных данных законного представителя (заполняется законным представителем абитуриента).doc"
              href="/file/Заполненное согласие на обработку персональных данных законного представителя (заполняется законным представителем абитуриента).doc"
            >
              Заполненное согласие на обработку персональных данных законного представителя (заполняется законным
              представителем абитуриента, не достигшего возраста 18 лет)
            </a>
          </li>
          <li>Паспорт;</li>
          <li>Документ об образовании (аттестат с приложением);</li>
          <li>Фотографии 3х4 – 4 штуки;</li>
          <li>ИНН;</li>
          <li>СНИЛС;</li>
          <li>Школьная медицинская карта (для поступающих на очную форму обучения);</li>
          <li>
            Справка о прохождении медицинского осмотра по форме 086-У (нужна для специальностей: техник-электрик,
            воспитатель детей дошкольного возраста, учитель начальных классов);
          </li>
          <li>Военный билет/удостоверение гражданина, подлежащего призыву на военную службу (для юношей);</li>
          <li>
            Паспорт (разворот с фотографией и прописка) и ИНН одного из родителей (ПОСТУПАЮЩИМ НА ПЛАТНОЙ ОСНОВЕ).
          </li>
        </ul>
        <p>Для <b>иногородних абитуриентов</b> обязательно предоставление справки о наличии (отсутствии) судисмости и (или) факта уголовного преследования либо о прекращении уголовного преследования.</p>
        <p><b>«Северский промышленный колледж»</b> общежития не имеет и не обеспечивает поступающих жилыми помещениями.</p>
        <p>
          <b> Справки по телефонам 8(3823) 780-327, 780-333, 780-366 </b>
        </p>
        <p>
          пн. - чт. 9.00 - 17.00 <br />
          пт. 9.00 - 16.00
        </p>
        <p>
          ПРИЕМНАЯ КОМИССИЯ ОГБПОУ "СПК": <br />
          Адрес: г. Северск, ул. Крупской, 17, ауд. 112. <br />
          Телефон: +7(3823) 780-327; +780-333; 780-366 <br />
          E-mail: postupi@spospk.ru
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          text: 'Главная',
          href: '/',
        },
        {
          text: 'Подача документов для поступления',
          actrive: true,
        },
      ],
    }
  },
}
</script>