<style lang="scss" scoped>
a.ist {
  color: rgb(30, 60, 117) !important;
  font-weight: bold;
}
p {
  font-size: 18px;
}
h1 {
  color: rgb(24, 174, 194);
  margin: 15px 0;
}
ul {
  font-size: 18px !important;
}
ul li {
  list-style: none;
}
ul li::before {
  content: '-';
  padding-right: 10px;
}
</style>
<template>
  <b-container>
    <b-row>
      <b-col cols="12">
        <b-breadcrumb :items="items"></b-breadcrumb>
      </b-col>
      <b-col cols="12">
        <h1>Сроки приема документов</h1>
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col cols="12">
        <p>
          <b> Сроки приема документов: </b>
        </p>
        <ul>
          <li>на <b>очную форму</b> обучения <b>до 10 августа</b> включительно по специальностям 44.02.01 Дошкольное образование, 44.02.02 Преподавание в начальных классах;</li>
          <li>на <b>очную форму</b> обучения <b>до 15 августа</b> включительно (кроме специальностей: 44.02.01 Дошкольное образование, 44.02.02 Преподавание в начальных классах);</li>
          <li>на <b>заочную форму</b> обучения <b>до 26 сентября</b> включительно;</li>
          <li>при наличии свободных мест - <b>до 01 декабря</b> текущего года.</li>
          <p></p>
          <i>При наличии свободных мест прием документов продлевается на очную форму обучения <b>до 25 ноября</b> текущего года;</i>
        </ul>
        <p>
          <b>Сроки предоставления оригиналов документов об образовании: </b>
        </p>
        <ul>
          <li>на <b>очную форму</b> обучения <b>до 19 августа</b> включительно;</li>
          <li>на <b>заочную форму</b> обучения <b>до 27 сентября</b> включительно.</li>
        </ul>
        <p>
          <b> Сроки зачисления: </b>
        </p>
        <ul>
          <li>на <b>очную форму</b> обучения <b>22 августа</b> текущего года,</li>
          <li>на <b>заочную форму</b> <b>30 сентября</b>,</li>
        </ul>
          <p>
          <b>
            Справки по телефонам 8(3823) 780-327, 780-333, 780-366
          </b>
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          text: 'Главная',
          href: '/',
        },
        {
          text: 'Сроки приёма документов',
          actrive: true,
        },
      ],
    }
  },
}
</script>